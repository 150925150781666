import { toast } from "react-toastify";
import axios from "axios";
import { base_url } from "../../components/Transformation/Transformations";
// import { exnryptionKey } from "../../utils";
// import { AES, enc } from "crypto-js";

export const SET_PROFILE_REGISTER = "SET_PROFILE_REGISTER";
export const SET_PROFILE_UPDATE = "SET_PROFILE_UPDATE";
export const SET_AVAILABLETIME = "SET_AVAILABLETIME";
export const GET_CURRENT_LEADER = "GET_CURRENT_LEADERS";
export const DELETE_LEADER_PROFILE = "DELETE_LEADER_PROFILE";
export const CREATE_LEADER_PROFILE = "CREATE_LEADER_PROFILE";
export const UPDATE_LEADER_PROFILE = "UPDATE_LEADER_PROFILE";
export const GET_USER_BYEMAIL = "GET_USER_BYEMAIL";
export const GET_ALLUSER = "GET_ALLUSER";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const CREATE_WISH = "CREATE_WISH";
export const GET_ALLUSER_LOADER = "GET_ALLUSER_LOADER";
export const GET_ALL_SHORT_LEADER = "GET_ALL_SHORT_LEADER";
export const GET_ALL_LEADER = "GET_ALL_LEADER";

// const decryptPassword = () => {
//   const decrypted = AES.decrypt(
//     localStorage.getItem("password"),
//     exnryptionKey
//   ).toString(enc.Utf8);
//   return decrypted;
// };

export const deleteLeaderProfile = ({ index, token, mail }) => (dispatch) => {
  axios
    .delete(`${base_url}/clubusers/leader-profiles/${index}/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
      toast.success("You have deleted LeaderProfile in successfully !", {
        position: "bottom-right",
      });
      dispatch({ type: DELETE_LEADER_PROFILE, payload: response.data });
      dispatch(getAllLeader(token));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const deleteLearnerProfile = ({ index, token, mail }) => (dispatch) => {
  axios
    .delete(`${base_url}/clubusers/me/learner-profiles/${index}/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
      toast.success("You have deleted LearnerProfile in successfully !", {
        position: "bottom-right",
      });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const updateLearnerProfile = ({
  base_language,
  base_language_skill_level,
  training_language,
  training_language_skill_level,
  introduction,
  index,
  token,
  mail,
}) => (dispatch) => {
  axios
    .put(
      `${base_url}/clubusers/me/learner-profiles/${index}/`,
      {
        base_language,
        base_language_skill_level,
        training_language,
        training_language_skill_level,
        introduction,
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have updated LearnerProfile in successfully !", {
        position: "bottom-right",
      });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const updateLeaderProfile = ({
  teaching_language,
  teaching_language_skill_level,
  introduction,
  interested_topics,
  creditAdjustmentRate,
  index,
  token,
  mail,
}) => (dispatch) => {
  axios
    .put(
      `${base_url}/clubusers/leader-profiles/${index}/`,
      {
        teaching_language,
        teaching_language_skill_level,
        introduction,
        interested_topics,
        credit_adjustment_rate: creditAdjustmentRate,
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have updated LeaderProfile in successfully !", {
        position: "bottom-right",
      });
      dispatch({ type: UPDATE_LEADER_PROFILE, payload: response.data });
      dispatch(getAllLeader(token));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const registerLeaderProfile = ({
  introduction,
  token,
  mail,
  teaching_language,
  teaching_language_skill_level,
  interestedTopic,
  creditAdjustmentRate,
}) => (dispatch) => {
  axios
    .post(
      `${base_url}/clubusers/leader-profiles`,
      {
        teaching_language: teaching_language,
        teaching_language_skill_level: teaching_language_skill_level,
        introduction: introduction,
        interested_topics: interestedTopic,
        credit_adjustment_rate: creditAdjustmentRate
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have registered LeaderProfile in successfully !", {
        position: "bottom-right",
      });
      dispatch({ type: CREATE_LEADER_PROFILE, payload: response.data });
      dispatch(getUserByEmail({ token, mail }));
      dispatch(getAllLeader(token));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const registerLearnerProfile = ({
  training_language,
  training_language_skill_level,
  base_language,
  base_language_skill_level,
  introduction,
  token,
  mail,
}) => (dispatch) => {
  axios
    .post(
      `${base_url}/clubusers/learner-profiles/`,
      {
        base_language,
        base_language_skill_level,
        training_language,
        training_language_skill_level,
        introduction,
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have registered LearnerProfile in successfully !", {
        position: "bottom-right",
      });
      dispatch(getUserByEmail({ token, mail }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const registerWish = (wishDetails) => (dispatch, getState) => {
  const {
    language,
    day,
    hour,
    minute,
    timezone,
    meetingLength,
    interestedTopics,
    note,
    token,
    mail,
  } = wishDetails;
  const owner = getState().user.currentUser.results?.[0]?.id;
  axios
    .post(
      `${base_url}/wishes/`,
      {
        language,
        day,
        hour,
        minute,
        timezone,
        meeting_length: meetingLength,
        interested_topics: interestedTopics,
        note,
        owner,
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("Your wish successfully registered!", {
        position: "bottom-right",
      });
      dispatch({
        type: CREATE_WISH,
        payload: response.data,
      });
      dispatch(getUserByEmail({ token, mail }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An error occurred";
      toast.error(message, { position: "bottom-right" });
    });
};

export const profileRegister = (formData) => (dispatch) => {
  axios
    .post(`${base_url}/clubusers/`, formData)
    .then((response) => {
      toast.success("You have registered profile in successfully !", {
        position: "bottom-right",
      });
      return dispatch({
        type: SET_PROFILE_REGISTER,
        payload: response.data,
      });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const getUserByEmail = ({ token, mail }) => (dispatch) => {
  axios
    .get(`${base_url}/clubusers/?email=${mail}`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
      const userData = response.data.results[0];
      localStorage.setItem("userData", JSON.stringify(userData));
      return dispatch({
        type: GET_USER_BYEMAIL,
        payload: response.data.results[0],
      });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const getUserData = ({ token, mail }) => (dispatch) => {
  if (!token) {
    return;
  }

  const userData = localStorage.getItem("userData");
  let parsedData;

  if (userData) {
    try {
      parsedData = JSON.parse(userData);
    } catch (e) {
      parsedData = null;
    }
  } else {
    parsedData = null;  // Or you can set a default value
  }
  console.log('hui inside getUserData');
  if (parsedData) {
    dispatch({
      type: GET_USER_BYEMAIL,
      payload: parsedData,
    });
  } else {
    dispatch(getUserByEmail({ token, mail }));
  }
};

export const getAllUSER = ({ token, page = 1 }) => async (dispatch) => {
  dispatch({ type: GET_ALLUSER_LOADER, payload: true });

  try {
    const baseUrl = `${base_url}/clubusers/?page=${page}`;
    const allResults = [];
    let nextUrl = baseUrl;

    // Loop to handle pagination and accumulate all data
    while (nextUrl) {
      nextUrl = nextUrl.replace("http://", "https://");
      const { data } = await axios.get(nextUrl, {
        // When the user is not login, there is no token
        //        headers: {
        //          Authorization: `Token ${token}`,
        //        },
      });

      allResults.push(...data.results);
      nextUrl = data.next;
    }

    dispatch({ type: GET_ALLUSER, payload: { results: allResults } });
  } catch (error) {
    const message =
      error.response?.data?.status || "An unexpected error occurred";
    dispatch({ type: FETCH_DATA_FAILURE, payload: message });
    console.error("Error message:", error.message);
    toast.error(message, { position: "bottom-right" });
  } finally {
    dispatch({ type: GET_ALLUSER_LOADER, payload: false });
  }
};

export const getAllShortLeaderUser = (token, page = 1) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${base_url}/clubusers/short-leader-profiles?page=${page}`
        // When the user is not login, there is no token
        //        {
        //          headers: { Authorization: `Token ${token.token}` },
        //        }
      );
      return dispatch({ type: GET_ALL_SHORT_LEADER, payload: response.data });
    } catch (error) {
      const message =
        error.response?.data?.status || "An unexpected error occurred";
      console.error("Error message:", error.message);
      toast.error(message, { position: "bottom-right" });
    }
  };
};
export const FilterLeaderByLanguage = (token, language) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${base_url}/clubusers/short-leader-profiles?teaching_language=${language}`,
        {
          headers: { Authorization: "Token" + token },
        }
      );
      dispatch({ type: GET_ALL_SHORT_LEADER, payload: response.data });
    } catch (error) {
      const message =
        error.response?.data?.status || "An unexpected error occurred";
      console.error("Error message:", error.message);
      toast.error(message, { position: "bottom-right" });
    }
  };
};

export const profileUpdate = ({
  firstName,
  lastName,
  preferredName,
  birthday,
  school,
  grade,
  sex,
  timeZone,
  nativeLanguage,
  token,
  mail,
}) => (dispatch) => {
  axios
    .put(
      `${base_url}/clubusers/`,
      {
        first_name: firstName,
        last_name: lastName,
        preferred_name: preferredName,
        date_of_birth: birthday,
        school,
        grade,
        sex,
        timezone: timeZone,
        native_language: nativeLanguage,
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have registered profile in successfully !", {
        position: "bottom-right",
      });
      dispatch(getUserByEmail({ token, mail }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const partialProfileUpdate = (
  {
    firstName,
    lastName,
    birthYear,
    school,
    sex,
    timezone,
    firstLanguage,
    firstLanguageSkillLevel,
    secondLanguage,
    second_language_skill_level,
    mail,
    image,
    leaderProfile,
    credits,
    availabilities,
  },
  id,
  token
) => (dispatch) => {
  axios
    .patch(
      `${base_url}/clubusers/${id}/`,
      {
        first_name: firstName,
        last_name: lastName,
        year_of_birth: birthYear,
        school,
        sex,
        timezone: timezone,
        first_language: firstLanguage,
        first_language_skill_level: firstLanguageSkillLevel,
        second_language: secondLanguage,
        second_language_skill_level,
        image,
        credits,
        leader_profiles: leaderProfile,
        availabilities,
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have registered profile in successfully !", {
        position: "bottom-right",
      });
      dispatch(getUserByEmail({ token, mail }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const changePassword = ({ password, confirm_password, token }) => (
  dispatch
) => {
  axios
    .post(
      `${base_url}/clubusers/reset_password`,
      { password, confirm_password },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("Your password has been changed correctly.", {
        position: "bottom-right",
      });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const getLeaderById = ({ id, token }) => (dispatch) => {
  axios
    .get(`${base_url}/clubusers/leader-profiles/${id}/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
      return dispatch({ type: GET_CURRENT_LEADER, payload: response.data });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};
export const getAllLeader = (token) => (dispatch) => {
  axios
    .get(`${base_url}/clubusers/leader-profiles`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
      return dispatch({ type: GET_ALL_LEADER, payload: response.data });
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const addAvailableTime = ({ day, hour, minute, note, token, mail }) => (
  dispatch
) => {
  axios
    .post(
      `${base_url}/clubusers/availabilities`,
      { day, hour, minute, note },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have registered availableTime in successfully !", {
        position: "bottom-right",
      });
      dispatch(getUserByEmail({ token, mail }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const deleteAvailableTime = ({ id, token, mail }) => (dispatch) => {
  axios
    .delete(`${base_url}/clubusers/availabilities/${id}`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
      toast.success("You have deleted availableTime in successfully !", {
        position: "bottom-right",
      });
      dispatch(getUserByEmail({ token, mail }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const uploadImage = ({ image, token, mail, id }) => (dispatch) => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("owner", id);
  axios
    .post(`${base_url}/clubusers/me/Image/`, formData, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then((response) => {
      toast.success("You have changed image in successfully !", {
        position: "bottom-right",
      });
      dispatch(getUserByEmail({ token, mail }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const deleteImage = ({ token, mail, userId }) => (dispatch) => {
  axios
    .patch(
      `${base_url}/clubusers/${userId}/`,
      { image: null },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
    .then((response) => {
      toast.success("You have changed image in successfully !", {
        position: "bottom-right",
      });
      dispatch(getUserByEmail({ token, mail }));
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};
