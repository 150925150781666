import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { transform_languages } from "../../components/Transformation/Transformations";
import { Rating } from "@material-tailwind/react";
import '../../components/Cards/activelingo.css';

export default function ActiveLingoCard(props) {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const mail = localStorage.getItem("mail");
  const currentUser = useSelector((state) => state.user.currentUser);

  const viewLeader = () => {
    const leaderId = props?.id;
    if (!token || !mail || !currentUser) {
      toast.warn(t("generic.please_login"), {
        position: "bottom-right",
      });
      return;
    }
    navigation(`/leaders/profile/${leaderId}`);
  };

  const formatReviewCount = (count) => {
    if (!count) return "No Counts yet";
    if (count >= 1000) {
      return `(${(count / 1000).toFixed(1)} k Reviews)`;
    }
    return `(${count} reviews)`;
  };
  const formatReviewScore = (count) => {
    if (!count) return "No Score yet";
    return `${count.toFixed(1)}`;
  };

  return (
    <div className="flex flex-col sm:flex-row items-center gap-5 sm:gap-8 bg-[#cbdfc4] border-[1px] border-gray-500 rounded-xl p-[1.5rem] shadow-lg my-[auto] lg:my-0 mx-[auto] w-full lg:mx-0 max-w-[400px] sm:max-w-[480px] lg:max-w-full">
      <img
        src={
          props.image ||
          "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg"
        }
        alt="ActivityLingoLeader"
        className="w-[140px] md:w-[180px] object-cover h-[140px] md:h-[180px] border-[2px] border-gray-600 rounded-full"
        onClick={viewLeader}
      />
      <div className="text-center sm:text-left">
        <h3
          onClick={viewLeader}
          className="text-2xl md:text-3xl font-bold cursor-pointer mb-1 text-[#195d40]"
        >
          {props.name}
        </h3>

        <h5 className="text-lg font-semibold text-[#000000]">
          {t("active_lingo_card.teaching")}{" "}
          {t(transform_languages(props.teaching_language))}
        </h5>
        <h5 className="text-lg font-semibold text-[#000000]">
            {props.credit_adjustment_rate === 0 ? (
              <span className="flashing-text"> ({t("active_lingo_card.free")}) </span>
            ) : props.credit_adjustment_rate >= 1 &  props.credit_adjustment_rate < 60? (
              <span style={{ color: 'green' }}> ({(props.credit_adjustment_rate / 60 * 100).toFixed(0)}% {t("active_lingo_card.off")} {t("active_lingo_card.discount")}) </span>
            ) : null}
        </h5>

        <div className="mt-4">
        <Rating value={Math.round(props?.reviewScore) || 0} readonly />
          <p className="flex gap-2 justify-center sm:justify-start text-[#195d40] items-center text-base">
            {formatReviewScore(props?.reviewScore)}

            <span className="text-[#000000] text-base">
              {formatReviewCount(props?.reviewCount)}
            </span>
          </p>
        </div>
        <button
          className=" mt-3 bg-[#195d40] w-[200px] h-10 rounded-full text-white font-semibold hover:bg-[#004225] transition-all mb-auto"
          onClick={viewLeader}
        >
          {t("active_lingo_card.request_meeting_button")}
        </button>
      </div>
    </div>
  );
}
