import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Username from "../../assets/image/Username.svg";
import Password from "../../assets/image/Password.svg";
import Loader from "../../components/Loader/Loader";
import { login } from "../../store/actions/auth.action";
import { AES } from "crypto-js";
import { exnryptionKey } from "../../utils";
import { useTranslation } from "react-i18next";


export default function Login() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const changeEmail = (email) => {
    setEmail(email);
  };

  const changePassword = (password) => {
    setPassword(password);
  };

  const goToForgot = () => {
    navigation("/forgot-password");
  };

  const encryptPassword = () => {
    const encrypted = AES.encrypt(password, exnryptionKey).toString();
    localStorage.setItem("password", encrypted);
  };

  const sendData = () => {
    setLoading(true);
    if (!email || !password) {
      toast.warn(t("login.all_info"), {
        position: "bottom-right",
      });
      return;
    }
    encryptPassword();

    dispatch(
      login({
        email: email,
        password: password,
        navigation,
      })
    );
  };

  return (
    <div className="flex max-w-screen-2xl mx-auto h-full">
      <div className="flex flex-col items-center md:basis-1/2 bg-[#F5F5DC] w-[1440px] h-full">
        <div className="flex flex-col gap-16 mt-40 justify-center items-center ">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <img src={Username} alt="Vector" className="pl-1 lg:pl-5" />
            </div>
            <input
              type="text"
              id="input-group-1"
              className="bg-[#DDE3CF] pl-12 md:pl-20 w-[250px] md:w-[300px] xl:w-[400px] 2xl:w-[450px] h-[50px] md:h-[79px] rounded-3xl text-lg md:text-xl xl:text-2xl text-[#000000]"
              placeholder="Email"
              value={email}
              onChange={(e) => changeEmail(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <img src={Password} alt="Vector" className="pl-1 lg:pl-5" />
            </div>
            <input
              type="password"
              id="input-group-2"
              className="bg-[#DDE3CF] pl-12 md:pl-20 w-[250px] md:w-[300px] xl:w-[400px] 2xl:w-[450px] h-[50px] md:h-[79px] rounded-3xl text-lg md:text-xl xl:text-2xl text-[#000000]"
              placeholder={t("generic.password")}
              value={password}
              onChange={(e) => changePassword(e.target.value)}
            />
          </div>
          <button
            className="flex justify-center text-xl md:text-2xl lg:text-3xl font-bold text-[#004225] hover:underline decoration-green-950"
            onClick={goToForgot}
          >
            {t("login.forget")}
          </button>
        </div>
        {loading ? <Loader /> :
            <button
              className="w-[200px] h-[50px] ml:w-[300px] md:h-[60px] bg-[#004225] rounded-[30px] text-2xl xl:text-3xl text-[#F5F5F5] font-bold mt-16"
              onClick={sendData}
            >
              {t("login.login")}
            </button>
        }
      </div>
      <div className="hidden md:block basis-1/2 bg-[#004225]">
        <div className="flex flex-col items-center justify-center h-full">
          <span className="text-8xl font-bold leading-30 text-[#FFFFFF]">
            {t("login.login")}
          </span>
        </div>
      </div>
    </div>
  );
}
