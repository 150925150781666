import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { MenuBar } from "../../components/Menu/Menu";
import Meeting from "../../components/Table/Meeting";
import {
  getCompletedMeetingAsStudent,
  getCompletedMeetingAsTeacher,
  getPendingMeetingAsStudent,
  getPendingMeetingAsTeacher,
} from "../../store/actions/meeting.action";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Meetings() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const navigation = useNavigate();
  useEffect(() => {
      if (! token) {
        navigation(`/login`);
      }
  }, [token, navigation]);

  const [completedPageTeacher, setCompletedPageTeacher] = useState(1);
  const [pendingPageTeacher, setPendingPageTeacher] = useState(1);
  const [completedPageStudent, setCompletedPageStudent] = useState(1);
  const [pendingPageStudent, setPendingPageStudent] = useState(1);
  const [loading, setLoading] = useState(false);

  const getPendingMeetingOfTeacher = useSelector(
    (state) => state.meeting.getPendingMeetingTeacher
  );
  const getCompletedMeetingOfTeacher = useSelector(
    (state) => state.meeting.getCompletedMeetingTeacher
  );
  const getPendingMeetingOfStudent = useSelector(
    (state) => state.meeting.getPendingMeetingStudent
  );
  const getCompletedMeetingOfStudent = useSelector(
    (state) => state.meeting.getCompletedMeetingStudent
  );

  const fetchData = useCallback(
    (fetchType, page) => {
      setLoading(true);

      switch (fetchType) {
        case "completedTeacher":
          dispatch(getCompletedMeetingAsTeacher({ token, page }));
          break;
        case "pendingTeacher":
          dispatch(getPendingMeetingAsTeacher({ token, page }));
          break;
        case "completedStudent":
          dispatch(getCompletedMeetingAsStudent({ token, page }));
          break;
        case "pendingStudent":
          dispatch(getPendingMeetingAsStudent({ token, page }));
          break;
        default:
          break;
      }
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (token) {
      fetchData("completedTeacher", completedPageTeacher);
    }
  }, [completedPageTeacher, fetchData, token]);

  useEffect(() => {
    if (token) {
      fetchData("pendingTeacher", pendingPageTeacher);
    }
  }, [pendingPageTeacher, fetchData, token]);

  useEffect(() => {
    if (token) {
      fetchData("completedStudent", completedPageStudent);
    }
  }, [completedPageStudent, fetchData, token]);

  useEffect(() => {
    if (token) {
      fetchData("pendingStudent", pendingPageStudent);
    }
  }, [pendingPageStudent, fetchData, token]);

  useEffect(() => {
    if (
      getPendingMeetingOfTeacher.results ||
      getCompletedMeetingOfTeacher.results ||
      getPendingMeetingOfStudent.results ||
      getCompletedMeetingOfStudent.results
    ) {
      setLoading(false);
    }
  }, [
    getPendingMeetingOfTeacher.results,
    getCompletedMeetingOfTeacher.results,
    getPendingMeetingOfStudent.results,
    getCompletedMeetingOfStudent.results,
  ]);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  const PENDING_TABLE_HEAD = [
    "No",
    t("meeting.time"),
    t("meeting.length"),
    t("meeting.leader"),
    t("meeting.follower"),
    t("meeting.lang"),
    t("meeting.status"),
    t("meeting.zoom"),
    t("meeting.action"),
  ];

  const COMPLETED_TABLE_HEAD = [
    "No",
    t("meeting.time"),
    t("meeting.length"),
    t("meeting.leader"),
    t("meeting.follower"),
    t("meeting.lang"),
    t("meeting.status"),
    t("meeting.action"),
    "",
  ];

  const completedNumberOfPagesTeacher = Math.ceil(
    getCompletedMeetingOfTeacher?.count / 10
  );
  const pendingNumberOfPagesTeacher = Math.ceil(
    getPendingMeetingOfTeacher?.count / 10
  );
  const completedNumberOfPagesStudent = Math.ceil(
    getCompletedMeetingOfStudent?.count / 10
  );
  const pendingNumberOfPagesStudent = Math.ceil(
    getPendingMeetingOfStudent?.count / 10
  );

  return (
    <div className="max-w-screen-2xl mx-auto p-4 h-full">
      <Header openMenu={openMenuBar} />
      {!token ?
          <div className="text-xl lg:text-2xl mt-4 font-normal text-center py-2">
            <p>Please login ... at <a href="https://lingoclub.org/login" className="text-blue-500 hover:underline"> https://lingoclub.org/login </a></p>
          </div>

          :
          <div className="w-full flex flex-col gap-12 p-4 md:p-12">
            <div className="flex flex-col gap-1">
              <p className="flex justify-center text-1xl md:text-2xl lg:text-2xl 1xl:text-2xl font-bold">
                {t("meeting.completed_meeting_teacher")}
              </p>
              <Meeting
                TABLE_HEAD={COMPLETED_TABLE_HEAD}
                setPage={setCompletedPageTeacher}
                page={completedPageTeacher}
                numberOfPages={completedNumberOfPagesTeacher}
                TABLE_ROWS={getCompletedMeetingOfTeacher.results}
                isLoading={loading}
              />
            </div>
            <div className="flex flex-col gap-1">
              <p className="flex justify-center text-1xl md:text-2xl lg:text-2xl 1xl:text-2xl font-bold">
                {t("meeting.pending_meeting_teacher")}
              </p>
              <Meeting
                TABLE_HEAD={PENDING_TABLE_HEAD}
                setPage={setPendingPageTeacher}
                page={pendingPageTeacher}
                numberOfPages={pendingNumberOfPagesTeacher}
                TABLE_ROWS={getPendingMeetingOfTeacher.results}
                isLoading={loading}
              />
            </div>
            <div className="flex flex-col gap-1">
              <p className="flex justify-center text-1xl md:text-2xl lg:text-2xl 1xl:text-2xl font-bold">
                {t("meeting.completed_meeting_student")}
              </p>
              <Meeting
                TABLE_HEAD={COMPLETED_TABLE_HEAD}
                setPage={setCompletedPageStudent}
                page={completedPageStudent}
                numberOfPages={completedNumberOfPagesStudent}
                TABLE_ROWS={getCompletedMeetingOfStudent.results}
                isLoading={loading}
              />
            </div>
            <div className="flex flex-col gap-1">
              <p className="flex justify-center text-1xl md:text-2xl lg:text-2xl 1xl:text-2xl font-bold">
                {t("meeting.pending_meeting_student")}
              </p>
              <Meeting
                TABLE_HEAD={PENDING_TABLE_HEAD}
                setPage={setPendingPageStudent}
                page={pendingPageStudent}
                numberOfPages={pendingNumberOfPagesStudent}
                TABLE_ROWS={getPendingMeetingOfStudent.results}
                isLoading={loading}
              />
            </div>
          </div>
      }
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}
