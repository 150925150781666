import React, { useState } from "react";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TeamUserCard from "../../components/Cards/TeamUserCard";
import { MenuBar } from "../../components/Menu/Menu";
import { choose_image } from "../../components/Transformation/Transformations"
import { useTranslation } from "react-i18next";


function OurTeam() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };
  return (
    <div className="max-w-screen-2xl mx-auto px-4">
      <Header openMenu={openMenuBar} />
      <div>
        <div className="hidden xl:block absolute w-full max-w-[1200px] h-[140px] bg-[#004225] mt-[400px] left-1/2 transform -translate-x-1/2" />
        <div className="text-6xl font-bold ml-12 text-[#004225] z-10">
          {t("team.our_team")}
        </div>
        <div className="text-3xl font-normal mt-4 ml-12 z-10">
          {t("team.leaders")}
        </div>
        <div className="flex flex-col items-center relative">
          <div className="flex flex-col xl:flex-row justify-center gap-10 mt-24 mb-10 ml-10 z-10">
            <TeamUserCard
              name={"Harly Liu"}
              image={choose_image("ourteam.harly")}
              job={"Founder"}
              summary={t("team.harly_statement")}
            />
            <TeamUserCard
              className="mt-20"
              image={choose_image("generic.small_logo")}
              name={"Cynthia Chen"}
              job={"Founder in China"}
              summary={t("team.cynthia_statement")}
            />
            {/*
            <TeamUserCard
              className="mt-40"
              image={Jannat}
              name={"Jannat Mirza"}
              job={"V P"}
              summary={
                "I am the V P of The  Lingo Club, I am doing a great job by putting my hardship to put the company on next level of success."
              }
            />
            */}
          </div>
        </div>
      </div>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}

export default OurTeam;
