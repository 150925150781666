import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';

const QRCodeGenerator = ({ url }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (url) {
      QRCode.toCanvas(canvasRef.current, url, { width: 300 }, (error) => {
        if (error) console.error(error);
      });
    }
  }, [url]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default QRCodeGenerator;
