import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { MenuBar } from "../../components/Menu/Menu";
import { changePassword } from "../../store/actions/user.action";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


export default function ChangePassword() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_Password] = useState("");
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const navigation = useNavigate();
  useEffect(() => {
      if (! token) {
        navigation(`/login`);
      }
  }, [token, navigation]);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  const sendData = () => {
    if (!password || !confirm_password) {
      toast.warn(t("generic.enter_all"), {
        position: "bottom-right",
      });
      return;
    }

    if (password !== confirm_password) {
      toast.warn("Please check about your password again.", {
        position: "bottom-right",
      });
      return;
    }

    dispatch(changePassword({ password, confirm_password, token }));
  };

  return (
    <div className="max-w-screen-2xl mx-auto p-4 h-full">
      <Header openMenu={openMenuBar} />
      <div className="w-full flex flex-col border-[#004225] border-[1px] gap-20 p-4 pt-20 md:p-20 xl:p-8 pb-12">
        <p className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-bold text-center">
          Change Password
        </p>
        <div className="flex flex-col gap-12">
          <div className="flex flex-col items-center gap-4">
            <p className="flex text-xl md:text-2xl lg:text-3xl font-normal">
              Password:
            </p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-[1px] border-[#004225] ml-2 bg-[#F5F5DC] text-2xl w-[200px] md:w-[300px] lg:w-[400px]"
            />
          </div>
          <div className="flex flex-col items-center gap-4">
            <p className="flex text-xl md:text-2xl lg:text-3xl font-normal">
              ConfirmPassword:
            </p>
            <input
              type="password"
              value={confirm_password}
              onChange={(e) => setConfirm_Password(e.target.value)}
              className="border-[1px] border-[#004225] ml-2 bg-[#F5F5DC] text-2xl w-[200px] md:w-[300px] lg:w-[400px]"
            />
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
            onClick={() => { sendData() }}
          >
            {t("generic.save")}
          </button>
        </div>
      </div>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}
