import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { t } = useTranslation();

  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);

  return (
    <div className="p-8 flex flex-col h-[400px]">
      <div className="flex flex-wrap gap-4 justify-between">
        <div className="lingoFooterMark">
          <span className="break-keep select-none">Lingo Club</span>
          <div className="text-black text-sm font-normal">
            <i>Build your language skill to next level</i>
          </div>
        </div>
        <div className="flex flex-wrap justify-center md:justify-end gap-y-8 md:gap-y-8 gap-15 space-evenly">
          <div className="flex w-[250px] justify-center">
            <ul className="flex flex-col justify-between items-center md:items-start h-[200px] text-xl md:text-2xl">
              <li className="font-bold">{t("footer.company")}</li>
              <li className="select-none">
                <Link to={"/"}>{t("footer.home")}</Link>
              </li>
              <li style={{ whiteSpace: "nowrap" }} >
                <Link to={"/become-a-leader"}>{t("header.become_a_leader")}</Link>
              </li>
              <li style={{ whiteSpace: "nowrap" }} >
                <Link to={"/become-a-learner"}>{t("header.become_a_learner")}</Link>
              </li>
              <li style={{ whiteSpace: "nowrap" }} >
                <Link to={"/schedule-meeting"}>{t("header.schedule_meeting")}</Link>
              </li>
              <li style={{ whiteSpace: "nowrap" }} >
                <Link to={"/our-team"}>{t("footer.our_team")}</Link>
              </li>
            </ul>
          </div>
          <div className="flex w-[250px] justify-center">
            <ul className="flex flex-col justify-between items-center md:items-start h-[115px] text-xl md:text-2xl">
              <li className="font-bold">{t("footer.legal")}</li>
              <li>
                <Link to={"/terms-conditions"}>{t("footer.terms_conditions")}</Link>
              </li>
              <li>{t("footer.privacy")}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-end text-md font-semibold mt-4">
        &copy; {currentYear}
        <a
          href="https://lingoclub.org/"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-1 underline"
        >
          LingoClub
        </a>
        . All rights reserved
      </div>
    </div>
  );
}

export default Footer;
