import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

import {
  addAvailableTime,
  deleteAvailableTime,
  deleteImage,
  deleteLeaderProfile,
  getAllLeader,
  getUserData,
  partialProfileUpdate,
  updateLeaderProfile,
  uploadImage,
} from "../../store/actions/user.action";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { MenuBar } from "../../components/Menu/Menu";
import ProfileTable from "../../components/Table/ProfileTable";
import { AddLeaderProfile } from "../../components/Dialog/AddLeaderProfile";
import upArrow from "../../assets/image/upArrow.svg";
import ChangeImage from "../../assets/image/change.svg";
import WeekDayButton from "../../components/Button/WeekDayButton";
// import MeetingTimeButton from "../../components/Button/MeetingTimeButton";
import TimeTable from "../../components/Table/TimeTable";
// import DatePicker from "react-datepicker";
// import { setHours, setMinutes } from "date-fns";
import {
  transform_gender,
  transform_skillLevel,
  transform_timezone,
  transform_languages,
  transform_timeslot
} from "../../components/Transformation/Transformations";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";
import QRCodeGenerator from '../../utils/qrcode';
import { useNavigate } from "react-router-dom";


export default function UserProfile() {
  const { t } = useTranslation();

  // const [birthday, setBirthday] = useState("");
  const [birthYear, setBirthYear] = useState(new Date().getFullYear().toString());
  const handleYearChange = (e) => {
    const inputYear = e.target.value;
    // Only accept a 4-digit number as valid input
    if (inputYear.length <= 4 && !isNaN(inputYear)) {
      setBirthYear(inputYear);
    } else {
      setBirthYear(new Date().getFullYear().toString());
    }
  };
  // Generate an array of years for the dropdown
  const years = [];
  let currentYear = new Date().getFullYear();
  let minYear = 1950;
  for (let year = currentYear; year >= minYear; year--) {
    years.push(year);
  }

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [preferredName, setPreferredName] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [addLeader, setAddLeader] = useState(false);
  //   const [addLearner, setAddLearner] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [firstLanguage, setFirstLanguage] = useState("");
  const [firstLanguageSkillLevel, setFirstLanguageSkillLevel] = useState("");
  const [secondLanguage, setSecondLanguage] = useState("");
  const [secondLanguageSkillLevel, setSecondLanguageSkillLevel] = useState("");
  const [sex, setSex] = useState("");
  const [school, setSchool] = useState("");
  const [tempImage, setTempImage] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  const [weekDay, setWeekDay] = useState("");
  const [selectWeekDay, setSelectWeekDay] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [time, setTime] = useState(""); // eslint-disable-line no-unused-vars
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [note, setNote] = useState("");
  const [isAvailableTimeChange, setIsAvailableTimeChange] = useState(false);
  const [currentDay, setCurrentDay] = useState(""); // eslint-disable-line no-unused-vars
  // const [selectTime, setSelectTime] = useState("");
  const [availabilityData, setAvailabilityData] = useState({});
  // const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  // const [selectedDayTime, setSelectedDayTime] = useState({ day: "", time: "" });
  const [currentTab, setCurrentTab] = useState("profile");
  const [loading, setLoading] = useState(true);
  const [savingImage, setSavingImage] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");
  const [invitationUrl, setInvitationUrl] = useState("");

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const token = localStorage.getItem("token");
  const navigation = useNavigate();
  useEffect(() => {
      if (! token) {
        navigation(`/login`);
      }
  }, [token, navigation]);

  const mail = localStorage.getItem("mail");
  const userProfile = useSelector((state) => state.user.currentUser);
  const user_timezone = userProfile.timezone;
  const leaderData = useSelector((state) => state.user.getLeaders);
  const parsedUserProfile = useMemo(() => {
    let parsedProfile;
    try {
      if (userProfile && typeof userProfile === "string" && userProfile.trim() !== "") {
        parsedProfile = JSON.parse(userProfile);
      } else {
        parsedProfile = userProfile;
      }
    } catch (error) {
      console.error("Error parsing userProfile JSON:", error);
      parsedProfile = {};
    }
    return parsedProfile;
  }, [userProfile]);

  useEffect(() => {
    if (token && mail) dispatch(getUserData({ token, mail }));
    dispatch(getAllLeader(token));
  }, [dispatch, token, mail]);

  const closeIsChange = () => {
    setIsChange(false);
    setTempImage(null);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setTempFile(file);
    if (file) {
      setTempImage(URL.createObjectURL(file));
    }
    event.target.value = null;
  };

  const rowDelete = (index) => {
    const id = parsedUserProfile?.availabilities[index]?.id;
    dispatch(deleteAvailableTime({ id, token, mail }));
  };

  const deleteLeader = (index) => {
    dispatch(
      deleteLeaderProfile({
        token,
        mail,
        index: index,
      })
    );
  };

  const updateLeader = (
    teaching_language,
    teaching_language_skill_level,
    introduction,
    interested_topics,
    creditAdjustmentRate,
    index
  ) => {
    dispatch(
      updateLeaderProfile({
        token,
        mail,
        teaching_language,
        teaching_language_skill_level,
        introduction,
        interested_topics,
        creditAdjustmentRate,
        index: leaderData?.results[index]?.id,
      })
    );
  };

  useEffect(() => {
    if (parsedUserProfile) {
      const userData = parsedUserProfile;
      setFirstName(userData?.first_name || "");
      setLastName(userData?.last_name || "");
      // setBirthday(userData?.date_of_birth || "");
      setBirthYear(userData?.year_of_birth || "");
      setSchool(userData?.school || "");
      setSex(userData?.sex || "");
      setTimeZone(userData?.timezone || "");
      setFirstLanguage(userData?.first_language || "");
      setFirstLanguageSkillLevel(userData?.first_language_skill_level || "");
      setSecondLanguage(userData?.second_language || "");
      setSecondLanguageSkillLevel(userData?.second_language_skill_level || "");
    }
  }, [parsedUserProfile]);

  const sendData = () => {
    // const prevBirthDate = parsedUserProfile?.year_of_birth;
//    const formatDate = (date) => {
//      if (!date) return null;
//      const d = new Date(date);
//      const year = d.getFullYear();
//      const month = String(d.getMonth() + 1).padStart(2, "0");
//      const day = String(d.getDate()).padStart(2, "0");
//      return `${year}-${month}-${day}`;
//    };

    // const formattedBirthday = birthday ? formatDate(birthday) : prevBirthDate;
    const prevSex = parsedUserProfile?.sex;
    const prevFirstLanguage = parsedUserProfile?.first_language;
    const prevFirstLanguageSkillLevel =
      parsedUserProfile?.first_language_skill_level;
    const prevSecondLanguageSkillLevel =
      parsedUserProfile?.second_language_skill_level;
    const profileData = {
      firstName: firstName,
      lastName: lastName,
      preferredName: firstName,
      // birthday: formattedBirthday,
      birthYear: birthYear,
      school: school,
      sex: sex || prevSex,
      timezone: timeZone,
      firstLanguage: firstLanguage || prevFirstLanguage,
      firstLanguageSkillLevel:
        firstLanguageSkillLevel || prevFirstLanguageSkillLevel,
      secondLanguage: secondLanguage,
      second_language_skill_level:
        secondLanguageSkillLevel || prevSecondLanguageSkillLevel,
      mail,
    };
    const id = parsedUserProfile?.id;
    dispatch(partialProfileUpdate(profileData, id, token));
    setIsChange(false);
  };

  useEffect(() => {
    let dayArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let newDay = new Date().getDay();
    setSelectWeekDay(dayArray[newDay]);
    setCurrentDay(newDay);
  }, []);

  const formattedAvailability = useMemo(() => {
    const availabilities = parsedUserProfile?.availabilities || [];
    const availability = {};
    availabilities.forEach((slot) => {
      let [zonedDay, formattedDate] = transform_timeslot(slot, user_timezone);
      if (!availability[zonedDay]) {
        availability[zonedDay] = [];
      }
      availability[zonedDay].push(formattedDate);
    });
    return availability;
  }, [user_timezone, parsedUserProfile?.availabilities]);

  useEffect(() => {
    if (formattedAvailability) {
      setAvailabilityData(formattedAvailability);
    }
  }, [formattedAvailability]);

  const MAX_IMAGE_SIZE_KB = 500
  const saveImage = async () => {
    if (!tempFile) {
      toast.warn("There is no image.", {
        position: "bottom-right",
      });
      return;
    }

    const imageSizeInMB = tempFile.size / (1024); // Convert the size to KB

    if (imageSizeInMB > MAX_IMAGE_SIZE_KB) {
      alert(`Image size is too large. Please select an image smaller than ${MAX_IMAGE_SIZE_KB} KB.`);
      return;
    }
    setSavingImage(true);
    try {
      await dispatch(
        uploadImage({
          token,
          mail,
          image: tempFile,
          id: parsedUserProfile?.id,
        })
      );
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setTempImage(null);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setSavingImage(false);
    }
  };

  const deleteImg = () => {
    dispatch(
      deleteImage({
        token,
        mail,
        userId: parsedUserProfile?.id,
      })
    );
    setTempImage(null);
    setTempFile(null);
  };
  const addTime = () => {
    if (weekDay === "" || hour === "" || minute === "") {
      toast.warn(t("generic.enter_all"), {
        position: "bottom-right",
      });
      return;
    }

    // backend will add user's current timezone to the record.
    // We assume that users always want a timeslot (for example, PST 8:00 PM) no matter it is daylight saving or not
    // For frontend to display the timeslot, it should be displayed based on both of the record's timezone and viewer's timezone
    dispatch(
      addAvailableTime({
        day: weekDay,
        note,
        hour: hour,
        minute: minute,
        token,
        mail,
      })
    );

    setWeekDay("");
    setTime("");
    setHour("");
    setMinute("");
    setNote("");
  };
  const SaveTime = () => {
    setIsAvailableTimeChange(false);
  };

  const data = [
    {
      label: t("user_home.profile"),
      value: "profile",
    },
    {
      label: t("user_home.leader_profile"),
      value: "leaderProfile",
    },
  ];

  const openIsChange = () => {
    setIsChange(true);
  };

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };
  const handleTimeChange = (e) => {
    const value = e.target.value;
    setTime(value);

    if (value) {
      const [time, period] = value.split(" ");
      let [hour, minute] = time.split(":").map(Number);

      if (period === "pm" && hour !== 12) {
        hour += 12;
      } else if (period === "am" && hour === 12) {
        hour = 0;
      }

      setHour(hour);
      setMinute(minute);
    } else {
      setHour("");
      setMinute("");
    }
  };

  const TimeSlotsList = ({ day, data, selectedDayTime }) => {
    return (
      <div
        className={`slotCard border border-transparent bg-white border-1 rounded-sm p-2`}
      >
        <div
          className={`relative flex items-center justify-between gap-4 text-gray-700 shadow-none`}
        >
          <h5
            className={`text-xl font-semibold leading-snug tracking-normal text-gray-700 font-sans`}
          >
            {data}
          </h5>
        </div>
      </div>
    );
  };
  const handleTabChange = (value) => {
    setCurrentTab(value);
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    setInvitationCode(parsedUserProfile?.invitation_code);
    setInvitationUrl("https://lingoclub.org/?ref=" + invitationCode);
  }, [leaderData, invitationCode, parsedUserProfile]);


  return (
    <div className="max-w-screen-2xl mx-auto p-4 h-full">
      <Header openMenu={openMenuBar} />
      <Tabs value={currentTab}>
        <TabsHeader className="bg-[#004225]">
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => {
                handleTabChange(value);
              }}
            >
              <div className="flex items-center gap-2">{label}</div>
            </Tab>
          ))}
        </TabsHeader>
        {currentTab === "leaderProfile" && (
          <p className="text-lg font-medium leading-relaxed text-gray-800 mt-4 font p-2 bg-[#0042254f] border-l-4 border-green-900 rounded-lg shadow-sm">
            {t("user_home.attention_header")}
          </p>
        )}

        <TabsBody className="min-h-[500px]">
          <TabPanel key={"profile"} value={"profile"}>
            <div className="w-full flex flex-col gap-4 p-2 pb-12">
              <button className="flex justify-center">
                <img
                  src={ChangeImage}
                  alt="changeProfile"
                  className="md:w-[40px] md:h-[40px]"
                  onClick={openIsChange}
                />
              </button>
              <div className="flex flex-col xl:flex-row items-center justify-center gap-8">
                <div className="basis-1/2 flex flex-col items-center">
                  <div className="flex gap-2.5 items-center xl:w-[300px] mb-3 mt-10">
                    <p className="text-lg md:text-2xl font-medium">
                      {t("user_home.first_name")}:
                    </p>
                    {isChange === false ? (
                      <p className="text-sm md:text-xl font-light">
                        {parsedUserProfile?.first_name}
                      </p>
                    ) : (
                      <input
                        type="text"
                        className="border-[1px] pl-2 border-[#004225] bg-[#F5F5DC] w-[100px]"
                        value={firstName || ""}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="flex gap-2.5 items-center xl:w-[300px] mb-3">
                    <p className="text-lg md:text-2xl font-medium">
                      {t("user_home.last_name")}:
                    </p>
                    {isChange === false ? (
                      <p className="text-sm md:text-xl font-light">
                        {parsedUserProfile?.last_name}
                      </p>
                    ) : (
                      <input
                        type="text"
                        className="border-[1px] pl-2 border-[#004225] bg-[#F5F5DC] w-[100px]"
                        value={lastName || ""}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    )}
                  </div>

                  <div className="flex gap-2.5 items-center xl:w-[300px] mb-3">
                    <p className="text-lg md:text-2xl font-medium">
                      {t("user_home.email")}:
                    </p>
                    <p className="text-sm md:text-xl font-light">{mail}</p>
                  </div>
                  <div className="flex gap-2.5 items-center xl:w-[300px]">
                    <p className="text-lg md:text-2xl font-medium">
                      {t("user_home.timezone")}:
                    </p>
                    {isChange === false ? (
                      <p className="text-sm md:text-xl font-light ml-2">
                        {t(transform_timezone(parsedUserProfile?.timezone))}
                      </p>
                    ) : (
                      <select
                        type="select"
                        className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] w-[300px] h-[40px]"
                        value={timeZone}
                        onChange={(e) => setTimeZone(e.target.value)}
                      >
                        <option value="">{t("user_home.select")}</option>
                        <option value="US/Pacific">
                          {t("generic.timezone_pacific")}
                        </option>
                        <option value="US/Eastern">
                          {t("generic.timezone_eastern")}
                        </option>
                        <option value="US/Mountain">
                          {t("generic.timezone_mountain")}
                        </option>
                        <option value="US/Central">
                          {t("generic.timezone_central")}
                        </option>
                        <option value="PRC">{t("generic.timezone_prc")}</option>
                      </select>
                    )}
                  </div>
                </div>
                <div className="basis-1/2 flex flex-col gap-4 items-center">
                  <div className="flex flex-col bg-[#0042254f] items-center justify-center p-4 border-4 border-slate-500 rounded-xl w-80 h-40">
                    <img src={upArrow} alt="upArrow" className="size-12 mb-2" />
                    <p className="text-center text-xl text-[#0d3624]">
                      {t("user_home.become_leader")}
                    </p>
                  </div>
                </div>
              </div>
              {isChange && (
                <div className="flex justify-center gap-4">
                  <button
                    className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                    onClick={closeIsChange}
                  >
                    {t("generic.cancel")}
                  </button>
                  <button
                    className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                    onClick={sendData}
                  >
                    {t("generic.save")}
                  </button>
                </div>
              )}
            </div>
            <div>
              <p className="text-lg font-medium leading-relaxed text-gray-800 mt-4 font p-2 bg-[#0042254f] border-l-4 border-green-900 rounded-lg shadow-sm text-center">
                <li>{t("user_home.refer_other_1")}</li>
                <li>{t("user_home.refer_other_2")}</li>
                {t("user_home.refer_other_3")} {"\u00A0\u00A0\u00A0"} { invitationUrl }
              </p>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <h1>QR Code for the URL: { invitationUrl }</h1>
              <QRCodeGenerator url={ invitationUrl } />
            </div>
          </TabPanel>
          <TabPanel key={"leaderProfile"} value={"leaderProfile"}>
            <div className="flex flex-col gap-10 mt-2">
              <div className="flex flex-col items-center gap-2 rounded-2xl mt-2">
                <div className="relative w-full text-center">
                  <span className="relative bg-[#f5f5dc] px-3 text-lg font-bold text-cyan-900 uppercase tracking-wider">
                    {t("user_home.my_picture")}
                  </span>
                </div>
                {!parsedUserProfile?.image && (
                  <p>{t("user_home.upload_picture")}</p>
                )}
                <div className="relative border-[1px] p-1 border-[#000000] w-[120px] h-[120px] md:w-[200px] md:h-[200px] rounded-full flex items-center justify-center">
                  {loading || savingImage ? (
                    <Loader />
                  ) : (
                    <img
                      src={
                        tempImage ||
                        (parsedUserProfile?.image
                          ? `${parsedUserProfile?.image}`
                          : "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg")
                      }
                      alt="userProfileImage"
                      className="w-full h-full object-cover rounded-full"
                    />
                  )}
                </div>

                <div className="flex gap-2 mt-1 md:mt-5">
                  <button
                    className="bg-[#004225] rounded-[5px] text-white py-0.5 px-3"
                    onClick={() => fileInputRef.current.click()}
                  >
                    {t("generic.edit")}
                  </button>
                  {tempImage === null && parsedUserProfile?.image && (
                    <button
                      className="bg-[#004225] rounded-[5px] py-0.5 px-3 text-white"
                      onClick={deleteImg}
                    >
                      {t("generic.delete")}
                    </button>
                  )}
                  {tempImage !== null && (
                    <div className="flex gap-2">
                      <button
                        className="bg-[#004225] rounded-[5px] py-0.5 px-3 text-white"
                        onClick={saveImage}
                      >
                        {t("generic.save")}
                      </button>
                      <button
                        className="bg-[#004225] rounded-[5px] py-0.5 px-3 text-white"
                        onClick={(e) => setTempImage(null)}
                      >
                        {t("generic.cancel")}
                      </button>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
              <div className="w-full flex flex-col gap-4 p-2 pb-12">
                <div className="relative w-full text-center">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <span className="relative bg-[#f5f5dc] px-3 text-lg font-bold text-cyan-900 uppercase tracking-wider">
                    {t("user_home.public_info")}
                  </span>
                </div>
                <button className="flex justify-end">
                  <img
                    src={ChangeImage}
                    alt="changeProfile"
                    className="md:w-[40px] md:h-[40px]"
                    onClick={openIsChange}
                  />
                </button>
                <div className="flex gap-32 items-center">
                  <div className="w-1/2 flex flex-col items-center gap-3">
                    <div className="flex gap-2.5 items-center w-full">
                      <p className="text-lg md:text-2xl font-medium w-2/3">
                        {t("user_home.1st_lang")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light w-1/3">
                          {t(
                            transform_languages(
                              parsedUserProfile?.first_language
                            )
                          )}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] w-1/3"
                          value={firstLanguage || ""}
                          onChange={(e) => setFirstLanguage(e.target.value)}
                        >
                          <option value="">{t("user_home.select")}</option>
                          <option value="EN">{t("generic.english")}</option>
                          <option value="ES">{t("generic.spanish")}</option>
                          <option value="CN">{t("generic.chinese")}</option>
                        </select>
                      )}
                    </div>
                    <div className="flex gap-2.5 items-center w-full">
                      <p className="text-lg md:text-2xl font-medium w-2/3">
                        {t("user_home.1st_lang_skill")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light w-1/3">
                          {t(
                            transform_skillLevel(
                              parsedUserProfile?.first_language_skill_level
                            )
                          )}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] w-1/3"
                          value={firstLanguageSkillLevel || ""}
                          onChange={(e) =>
                            setFirstLanguageSkillLevel(e.target.value)
                          }
                        >
                          <option value={""}>{t("user_home.select")}</option>
                          <option value={"1"}>{t("generic.skill_1")}</option>
                          <option value={"2"}>{t("generic.skill_2")}</option>
                          <option value={"3"}>{t("generic.skill_3")}</option>
                          <option value={"4"}>{t("generic.skill_4")}</option>
                          <option value={"5"}>{t("generic.skill_5")}</option>
                        </select>
                      )}
                    </div>
                    <div className="flex gap-2.5 items-center w-full">
                      <p className="text-lg md:text-2xl font-medium w-2/3">
                        {t("user_home.2nd_lang")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light w-1/3">
                          {t(
                            transform_languages(
                              parsedUserProfile?.second_language
                            )
                          )}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] w-1/3"
                          value={secondLanguage || ""}
                          onChange={(e) => setSecondLanguage(e.target.value)}
                        >
                          <option value="">{t("user_home.select")}</option>
                          <option value="EN">{t("generic.english")}</option>
                          <option value="ES">{t("generic.spanish")}</option>
                          <option value="CN">{t("generic.chinese")}</option>
                        </select>
                      )}
                    </div>
                    <div className="flex gap-2.5 items-center w-full">
                      <p className="text-lg md:text-2xl font-medium w-2/3">
                        {t("user_home.2nd_lang_skill")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light w-1/3">
                          {t(
                            transform_skillLevel(
                              parsedUserProfile?.second_language_skill_level
                            )
                          )}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] w-1/3"
                          value={secondLanguageSkillLevel || ""}
                          onChange={(e) =>
                            setSecondLanguageSkillLevel(e.target.value)
                          }
                        >
                          <option value={""}>{t("user_home.select")}</option>
                          <option value={"1"}>{t("generic.skill_1")}</option>
                          <option value={"2"}>{t("generic.skill_2")}</option>
                          <option value={"3"}>{t("generic.skill_3")}</option>
                          <option value={"4"}>{t("generic.skill_4")}</option>
                          <option value={"5"}>{t("generic.skill_5")}</option>
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="w-1/2 flex flex-col gap-4 items-center">
                    <div className="flex w-full items-center gap-4">
                      <p className="text-lg md:text-2xl font-medium w-2/3">
                        {isChange
                          ? t("user_home.birthday")
                          : t("user_home.age")}
                      </p>
                      {isChange ? (
//                        <DatePicker
//                          style={{ width: "100px" }}
//                          selected={birthday}
//                          onChange={(date) => {
//                            setBirthday(date);
//                          }}
//                          className="rounded-none px-2.5 bg-[#F5F5DC] border border-[#004225] text-gray-900 text-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-[94%] p-0 ml-3"
//                          placeholderText={t("user_home.select_date")}
//                        />
//                            <div>
//                              <label htmlFor="birthYear" className="block text-lg font-medium text-gray-700">
//                                Year of Birth:
//                              </label>
//                              <input
//                                id="birthYear"
//                                type="number"
//                                value={birthYear}
//                                onChange={handleYearChange}
//                                placeholder="Enter year of birth"
//                                className="border border-[#004225] p-2 rounded-none bg-[#F5F5DC] text-gray-900 w-[100px] ml-3"
//                              />
//                            </div>
                           <div>
                             <label htmlFor="birthYear" className="block text-lg font-medium text-gray-700">
                               Year of Birth:
                             </label>
                             <select
                               id="birthYear"
                               value={birthYear}
                               onChange={handleYearChange}
                               className="border border-[#004225] p-2 rounded-none bg-[#F5F5DC] text-gray-900 w-[150px] ml-3"
                             >
                               <option value="" disabled>
                                 Select a year
                               </option>
                               {years.map((year) => (
                                 <option key={year} value={year}>
                                   {year}
                                 </option>
                               ))}
                             </select>
                           </div>
                      ) : (
                        <p className="text-sm md:text-xl font-light ml-2 w-1/3">
                          {parsedUserProfile?.age}
                        </p>
                      )}
                    </div>
                    <div className="flex w-full items-center">
                      <p className="text-lg md:text-2xl font-medium w-2/3">
                        {t("user_home.school")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light w-1/3">
                          {parsedUserProfile?.school}
                        </p>
                      ) : (
                        <input
                          type="text"
                          className="border-[1px] border-[#004225] pl-2 bg-[#F5F5DC] text-lg w-1/3"
                          value={school || ""}
                          onChange={(e) => setSchool(e.target.value)}
                        />
                      )}
                    </div>
                    <div className="flex items-center w-full">
                      <p className="text-lg md:text-2xl font-medium w-2/3">
                        {t("user_home.sex")}:
                      </p>
                      {isChange === false ? (
                        <p className="text-sm md:text-xl font-light w-1/3">
                          {t(transform_gender(parsedUserProfile?.sex))}
                        </p>
                      ) : (
                        <select
                          type="select"
                          className="border-[1px] text-lg border-[#004225] pl-2 bg-[#F5F5DC] w-1/3"
                          value={sex}
                          onChange={(e) => setSex(e.target.value)}
                        >
                          <option value="">{t("user_home.select")}</option>
                          <option value="M">{t("generic.male")}</option>
                          <option value="F">{t("generic.female")}</option>
                          <option value="O">{t("generic.other_gender")}</option>
                        </select>
                      )}
                    </div>
                  </div>
                </div>
                {isChange && (
                  <div className="flex justify-end gap-4">
                    <button
                      className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                      onClick={closeIsChange}
                    >
                      {t("generic.cancel")}
                    </button>
                    <button
                      className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                      onClick={sendData}
                    >
                      {t("generic.save")}
                    </button>
                  </div>
                )}
              </div>
              <div className="relative w-full text-center">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <span className="relative bg-[#f5f5dc] px-3 text-lg font-bold text-cyan-900 uppercase tracking-wider">
                  {t("user_home.my_course")}
                </span>
              </div>
              <div className="flex gap-5 justify-between">
                <p className="w-[calc(100% - 100px)]">
                  {t("user_home.add_new_course")}
                </p>
                <button
                  className="bg-[#004225] rounded-[5px] w-full max-w-[70px] h-[30px] xl:max-w-[100px] xl:h-[40px] text-white"
                  onClick={() => setAddLeader(true)}
                >
                  {t("generic.add")}
                </button>
              </div>
              <div className="flex flex-col gap-10">
                <ProfileTable
                  TABLE_ROWS={leaderData}
                  update={(
                    trainingLanguage,
                    teaching_language_skill_level,
                    changeIntroduction,
                    interested_topics,
                    creditAdjustmentRate,
                    index
                  ) =>
                    updateLeader(
                      trainingLanguage,
                      teaching_language_skill_level,
                      changeIntroduction,
                      interested_topics,
                      creditAdjustmentRate,
                      index
                    )
                  }
                  delete={(index) => deleteLeader(index)}
                />
              </div>
            </div>
            <div className="relative w-full text-center mt-10">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <span className="relative bg-[#f5f5dc] px-3 text-lg font-bold text-cyan-900 uppercase tracking-wider">
                {t("user_home.my_avail")}
              </span>
            </div>
            <div className="flex justify-end">
              <button>
                <img
                  src={ChangeImage}
                  alt="changeProfile"
                  className="md:w-[40px] md:h-[40px]"
                  onClick={(e) => setIsAvailableTimeChange(true)}
                />
              </button>
            </div>

            {!isAvailableTimeChange ? (
              <>
                <div className="flex justify-center">
                  {t("user_home.add_time_slot")}
                </div>
                <div className="flex justify-center">
                  {t("meeting.table_text")} <span style={{ color: 'red' }}> {t(transform_timezone(parsedUserProfile?.timezone))} </span>
                </div>
                <div className=" flex flex-col gap-8 md:gap-20 md:mt-10 justify-center">
                  <div className="flex flex-col gap-4 md:gap-12 items-center">
                    <div className="flex flex-wrap justify-center gap-4">
                      <div className="flex flex-col items-center">
                        <WeekDayButton
                          name={t("generic.mon")}
                          nowState={selectWeekDay}
                          onClick={() => {
                            setSelectWeekDay(t("generic.mon"));
                          }}
                          // isEnabled={currentDay <= 1}
                        />
                        <div className="mt-5">
                          {/* {currentDay <= 1 && ( */}
                          <div className="slots_wrapp flex flex-col gap-2 justify-center">
                            {availabilityData?.Mon?.filter((time) => {
                              return time;
                            })?.map((data, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <TimeSlotsList
                                    data={data}
                                    day={t("generic.mon")}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <WeekDayButton
                          name={t("generic.tue")}
                          nowState={selectWeekDay}
                          onClick={() => {
                            setSelectWeekDay(t("generic.tue"));
                          }}
                          // isEnabled={currentDay <= 2}
                        />
                        <div className="mt-5">
                          {/* {currentDay <= 2 && ( */}
                          <div className="slots_wrapp flex flex-col gap-2 justify-center">
                            {availabilityData?.Tue?.filter((time) => {
                              return time;
                            })?.map((data, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <TimeSlotsList
                                    data={data}
                                    day={t("generic.tue")}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <WeekDayButton
                          name={t("generic.wed")}
                          nowState={selectWeekDay}
                          onClick={() => {
                            setSelectWeekDay(t("generic.wed"));
                          }}
                          // isEnabled={currentDay <= 3}
                        />
                        <div className="mt-5">
                          {/* {currentDay <= 3 && ( */}
                          <div className="slots_wrapp flex flex-col gap-2 justify-center">
                            {availabilityData.Wed?.filter((time) => {
                              return time;
                            })?.map((data, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <TimeSlotsList
                                    data={data}
                                    day={t("generic.wed")}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <WeekDayButton
                          name={t("generic.thu")}
                          nowState={selectWeekDay}
                          onClick={() => {
                            setSelectWeekDay(t("generic.thu"));
                          }}
                          // isEnabled={currentDay <= 4}
                        />
                        <div className="mt-5">
                          {/* {currentDay <= 4 && ( */}
                          <div className="slots_wrapp flex flex-col gap-2 justify-center items-center">
                            {availabilityData?.Thu?.filter((dataFill) => {
                              return dataFill;
                            })?.map((data, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <TimeSlotsList
                                    data={data}
                                    day={t("generic.thu")}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <WeekDayButton
                          name={t("generic.fri")}
                          nowState={selectWeekDay}
                          onClick={() => {
                            setSelectWeekDay(t("generic.fri"));
                          }}
                          // isEnabled={currentDay <= 5}
                        />
                        <div className="mt-5">
                          {/* {currentDay <= 5 && ( */}
                          <div className="slots_wrapp flex flex-col gap-2 justify-center">
                            {availabilityData?.Fri?.filter((dataFill) => {
                              return dataFill;
                            }).map((data, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <TimeSlotsList
                                    data={data}
                                    day={t("generic.fri")}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <WeekDayButton
                          name={t("generic.sat")}
                          nowState={selectWeekDay}
                          onClick={() => {
                            setSelectWeekDay(t("generic.sat"));
                          }}
                          // isEnabled={currentDay <= 6}
                        />
                        <div className="mt-5">
                          {/* {currentDay <= 6 && ( */}
                          <div className="slots_wrapp flex flex-col gap-2 justify-center">
                            {availabilityData?.Sat?.filter((dataFill) => {
                              return dataFill;
                            })?.map((data, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <TimeSlotsList
                                    data={data}
                                    day={t("generic.sat")}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <WeekDayButton
                          name={t("generic.sun")}
                          nowState={selectWeekDay}
                          onClick={() => {
                            setSelectWeekDay(t("generic.sun"));
                          }}
                          // isEnabled={currentDay <= 0}
                        />
                        <div className="mt-5">
                          {/* {currentDay <= 0 && ( */}
                          <div className="slots_wrapp flex flex-col gap-2 justify-center">
                            {availabilityData.Sun?.filter((dataFill) => {
                              return dataFill;
                            })?.map((data, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <TimeSlotsList
                                    data={data}
                                    day={t("generic.sun")}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 md:gap-12 items-center">
                    {/* <div className="text-xl md:text-2xl xl:text-3xl font-bold">
                      Time Slots
                    </div> */}
                    {/* <div className="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-x-20 xl:gap-x-40"> */}
                    {/* <div className="flex flex-wrap justify-center gap-4">
                      {userProfile.availability_time &&
                        userProfile.availability_time.map((meetingTime) => {
                          return meetingTime.day === selectWeekDay ? (
                            <MeetingTimeButton
                              key={meetingTime.id}
                              time={meetingTime.time}
                            />
                          ) : null;
                        })}
                    </div> */}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col gap-8">
                <p className="text-xl md:text-2xl">
                  {t("user_home.add_day_avail_1")} <span style={{ color: 'red' }}> {t(transform_timezone(parsedUserProfile?.timezone))} </span> {t("user_home.add_day_avail_2")}
                </p>
                <div className="flex flex-col">
                  <div className="flex flex-col md:flex-row items-center justify-center gap-12">
                    <div className="flex items-center">
                      <p>{t("user_home.day")}</p>
                      <select
                        type={t("user_home.select")}
                        value={weekDay}
                        className="border-[1px] border-[#004225] ml-2 bg-[#F5F5DC] w-[120px] h-[50px] text-2xl"
                        onChange={(e) => setWeekDay(e.target.value)}
                      >
                        <option value="">{t("user_home.select")}</option>
                        <option value="Mon">{t("generic.mon")}</option>
                        <option value="Tue">{t("generic.tue")}</option>
                        <option value="Wed">{t("generic.wed")}</option>
                        <option value="Thu">{t("generic.thu")}</option>
                        <option value="Fri">{t("generic.fri")}</option>
                        <option value="Sat">{t("generic.sat")}</option>
                        <option value="Sun">{t("generic.sun")}</option>
                      </select>
                    </div>
                    <div className="flex items-center">
                      <p>{t("user_home.time_slot")}</p>
                      <select
                        type={t("user_home.select")}
                        value={time}
                        className="border-[1px] border-[#004225] ml-2 bg-[#F5F5DC] w-[150px] h-[50px] text-2xl"
                        onChange={handleTimeChange}
                      >
                        <option value="">{t("user_home.select")}</option>
                        <option value="00:00 am">00:00 am</option>
                        <option value="00:30 am">00:30 am</option>
                        <option value="01:00 am">01:00 am</option>
                        <option value="01:30 am">01:30 am</option>
                        <option value="02:00 am">02:00 am</option>
                        <option value="02:30 am">02:30 am</option>
                        <option value="03:00 am">03:00 am</option>
                        <option value="03:30 am">03:30 am</option>
                        <option value="04:00 am">04:00 am</option>
                        <option value="04:30 am">04:30 am</option>
                        <option value="05:00 am">05:00 am</option>
                        <option value="05:30 am">05:30 am</option>
                        <option value="06:00 am">06:00 am</option>
                        <option value="06:30 am">06:30 am</option>
                        <option value="07:00 am">07:00 am</option>
                        <option value="07:30 am">07:30 am</option>
                        <option value="08:00 am">08:00 am</option>
                        <option value="08:30 am">08:30 am</option>
                        <option value="09:00 am">09:00 am</option>
                        <option value="09:30 am">09:30 am</option>
                        <option value="10:00 am">10:00 am</option>
                        <option value="10:30 am">10:30 am</option>
                        <option value="11:00 am">11:00 am</option>
                        <option value="11:30 am">11:30 am</option>
                        <option value="12:00 am">12:00 am</option>
                        <option value="12:30 am">12:30 am</option>
                        <option value="01:00 pm">01:00 pm</option>
                        <option value="01:30 pm">01:30 pm</option>
                        <option value="02:00 pm">02:00 pm</option>
                        <option value="02:30 pm">02:30 pm</option>
                        <option value="03:00 pm">03:00 pm</option>
                        <option value="03:30 pm">03:30 pm</option>
                        <option value="04:00 pm">04:00 pm</option>
                        <option value="04:30 pm">04:30 pm</option>
                        <option value="05:00 pm">05:00 pm</option>
                        <option value="05:30 pm">05:30 pm</option>
                        <option value="06:00 pm">06:00 pm</option>
                        <option value="06:30 pm">06:30 pm</option>
                        <option value="07:00 pm">07:00 pm</option>
                        <option value="07:30 pm">07:30 pm</option>
                        <option value="08:00 pm">08:00 pm</option>
                        <option value="08:30 pm">08:30 pm</option>
                        <option value="09:00 pm">09:00 pm</option>
                        <option value="09:30 pm">09:30 pm</option>
                        <option value="10:00 pm">10:00 pm</option>
                        <option value="10:30 pm">10:30 pm</option>
                        <option value="11:00 pm">11:00 pm</option>
                        <option value="12:30 pm">11:30 pm</option>
                      </select>
                    </div>
                    <input
                      type="Note"
                      placeholder={t("user_home.note_text")}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      className="border-[1px] placeholder-darkGrey-500 border-[#004225] ml-2 h-[50px] bg-[#F5F5DC] rounded-[1px] text-1xl p-2 w-[200px] md:w-[300px] lg:w-[300px]"
                    />
                    <button
                      className="bg-[#004225] rounded-[5px] w-[50px] h-[30px] text-white"
                      onClick={() => {
                        addTime();
                      }}
                    >
                      {t("generic.add")}
                    </button>
                  </div>

                  <TimeTable
                    TABLE_ROWS={parsedUserProfile?.availabilities}
                    delete={(index) => rowDelete(index)}
                  />
                </div>
                <div className="flex justify-end gap-4">
                  <button
                    className="bg-[#004225] rounded-[5px] w-[100px] h-[50px] text-white"
                    onClick={(e) => setIsAvailableTimeChange(false)}
                  >
                    {t("generic.cancel")}
                  </button>
                  <button
                    className="bg-[#004225] rounded-[5px] w-[100px] h-[50px] text-white"
                    onClick={SaveTime}
                  >
                    {t("generic.save")}
                  </button>
                </div>
              </div>
            )}
          </TabPanel>
          {/* <TabPanel key={"learnerProfile"} value={"learnerProfile"}>
            <div className="flex flex-col gap-20">
              <ProfileTable
                TABLE_ROWS={userProfile.learner_profiles}
                update={(
                  baseLanguage,
                  baseLanguageSkill,
                  trainingLanguage,
                  trainingLanguageSkill,
                  changeIntroduction,
                  index
                ) =>
                  updateLearner(
                    baseLanguage,
                    baseLanguageSkill,
                    trainingLanguage,
                    trainingLanguageSkill,
                    changeIntroduction,
                    index
                  )
                }
                delete={(index) => deleteLearner(index)}
              />
              <div className="flex justify-center gap-4">
                <button
                  className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
                  onClick={() => setAddLearner(true)}
                >
                  Add
                </button>
                <button className="rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white bg-red-400">
                  Delete
                </button>
              </div>
            </div>
          </TabPanel> */}
        </TabsBody>
      </Tabs>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
      <AddLeaderProfile open={addLeader} close={() => setAddLeader(false)} />
      {/* <AddLearnerProfile open={addLearner} close={() => setAddLearner(false)} /> */}
    </div>
  );
}
