import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import List from "../../assets/image/List.svg";
import DownArrow from "../../assets/image/DownArrow.svg";
import globe from "../../assets/image/globe.svg";
import "./Header.css";
import { useTranslation } from "react-i18next";
import { getUserData } from "../../store/actions/user.action";

function Header(props) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.currentUser);

  let parsedUserProfile;
  try {
    if (userProfile && typeof userProfile === "string" && userProfile.trim() !== "") {
      parsedUserProfile = JSON.parse(userProfile);
    } else {
      parsedUserProfile = userProfile;
    }
  } catch (error) {
    console.error("Error parsing userProfile JSON:", error);
    parsedUserProfile = {};
  }
  const addBodyClass = (className) => document.body.classList.add(className);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getUserData({ token }));
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n, dispatch, token]);

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  return (
    <div className="header">
      <div className="flex flex-wrap justify-between">
        <div className="lingoMark">
          <Link to="/" className="break-keep select-none">
            Lingo Club
          </Link>
        </div>
        <div className="navBar hidden xl:block">
          <ul className="text-xl overflow-visible flex items-center">
            <li className="relative select-none focus:outline-none group hover:text-gray-900">
              <Link className="flex items-center gap-1" to={"/"}>
                {t("header.home")}
                <img className="w-4 h-4" src={DownArrow} alt="downArrow" />
              </Link>
              <div className="left-0 bg-white border rounded-md shadow-lg hidden group-hover:block absolute z-10 w-40">
                <div className="p-2">
                  <Link
                    className="hover:bg-gray-100 w-full text-base text-left"
                    to={"/introduction"}
                  >
                    {t("header.introduction")}
                  </Link>
                </div>
              </div>
            </li>
            <li className="select-none">
              <Link to={"/become-a-leader"}>{t("header.lingo_teacher")}</Link>
            </li>
            <li className="select-none">
              <Link to={"/become-a-learner"}>
                {t("header.lingo_learner")}
              </Link>
            </li>
            <li className="select-none">
              <Link to={"/schedule-meeting"}>
                {t("header.schedule_meeting")}
              </Link>
            </li>
            <li className="select-none">
              <Link to={"/conversation-questions"}>
                {t("header.conversation_questions")}
              </Link>
            </li>
            <li className="flex items-center select-none">
              <Link to={"/our-team"}>{t("header.our_team")}</Link>
            </li>
          </ul>
        </div>
        {token === null && (
          <div className="hidden xl:block py-2">
            <button className="pr-4">
              <Link to={"/login"}>{t("header.login")}</Link>
            </button>
            <button className="px-2 bg-[#004225] text-white rounded-full">
              <Link to={"/register"}>{t("header.register")}</Link>
            </button>
          </div>
        )}
        {token === null ? (
          <button className="xl:hidden justify-left" onClick={props.openMenu}>
            <img src={List} alt="Logo" className="w-10" />
          </button>
        ) : (
          <button
            onClick={() => {
              props.openMenu();
              addBodyClass("overflow-hidden");
            }}
          >
            <img
              src={
                parsedUserProfile?.image
                  ? `${parsedUserProfile.image}`
                  : "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg"
              }
              alt="UserImage"
              className="border-[1px] rounded-full border-[#004225] h-[50px] w-[50px]"
            />
          </button>
        )}
      </div>
      <div className="flex justify-end mt-2">
        <div className="relative">
          <img
            src={globe}
            alt="globe"
            className="absolute top-1/2 transform -translate-y-1/2 left-2 w-5 h-5 pointer-events-none"
          />
          <select
            value={selectedLanguage}
            onChange={changeLanguage}
            className="pl-7 pr-1 py-1 font-medium text-green-900 bg-transparent border-[1.5px] border-black rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="en">{t("header.header_lang_chinese")}</option>
            <option value="cn">{t("header.header_lang_english")}</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default Header;
